.footer {
    font-size: 0.8rem;
    background-color: black;
    color: gray;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, max-content));
    justify-content: space-around;
    margin-top: 5rem;
    padding: 1rem;
  }
  
  .footer a {
    color: gray;
    cursor: pointer;
    text-decoration: underline;
  }
  
  .footer-item {
    margin: 1rem;
  }
  
  .footer-contact {
    display: inline-flex;
    align-items: center;
  }
  
  .footer-contact img {
    filter: contrast(0.1);
    height: 1.4rem;
  }