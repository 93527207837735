.dashboard-container {
    display: flex;
    flex-wrap: wrap;
    background-color: lightgrey;
    gap: 1px;
}

.dashboard-item {
    padding: 0.5rem;
    min-width: 20rem;
    flex: 1 1 45%;
    background-color: white;
}